
.fancybox-enabled {
  overflow: hidden;

  body {
    overflow: visible;
    height: 100%;
  }

}


.fancybox-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99993;
  backface-visibility: hidden;
}

/* Make sure that the first one is on the top */
.fancybox-container ~ .fancybox-container {
  z-index: 99992;
}

.fancybox-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  opacity: 0;
  transition-timing-function: cubic-bezier(0.55, 0.06, 0.68, 0.19);
  backface-visibility: hidden;
}

.fancybox-container--ready .fancybox-bg {
  opacity: 1;
  transition-timing-function: cubic-bezier(0.2, 0.61, 0.36, 1);
}

.fancybox-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  z-index: 99994;
  transition: opacity .2s;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  direction: ltr;
}

.fancybox-show-controls .fancybox-controls {
  opacity: 1;
}

.fancybox-infobar {
  display: none;
}

.fancybox-show-infobar .fancybox-infobar {
  display: inline-block;
  pointer-events: all;
  margin: 3em 0 0;
}

.fancybox-infobar__body {
  display: inline-block;
  width: 70px;
  line-height: 44px;
  font-size: 13px;
  font-family: $fontName, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #ddd;
  background-color: rgba(30, 30, 30, 0.7);
  pointer-events: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased;
}

.fancybox-buttons {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  pointer-events: all;

  .popup-block__cross {
    position: static;
    height: 44px;
    width: 44px;
    
    &:hover {
      &:before {
        color: $linkColor;
        transition: color 0.2s ease;
      }
    }
    &:before {
      font-size: 22px;
      top: 10px;
    }
  }
}

.fancybox-show-buttons .fancybox-buttons {
  display: block;
}

.fancybox-slider-wrap {
  overflow: hidden;
  direction: ltr;
}

.fancybox-slider-wrap,
.fancybox-slider {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 99993;
  backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
}

.fancybox-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  outline: none;
  white-space: normal;
  text-align: center;
  z-index: 99994;
  -webkit-overflow-scrolling: touch;

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
  }

  & > * {
    display: inline-block;
    position: relative;
    padding: 24px;
    margin: 44px 0 44px;
    border-width: 0;
    vertical-align: middle;
    text-align: left;
    background-color: $white;
    overflow: auto;
  }

}


.fancybox-slide--image {
  overflow: hidden;

  &:before {
    display: none;
  }
}


.fancybox-content {
  display: inline-block;
  position: relative;
  margin: 44px auto;
  padding: 0;
  border: 0;
  width: 80%;
  height: calc(100% - 88px);
  vertical-align: middle;
  line-height: normal;
  text-align: left;
  white-space: normal;
  outline: none;
  font-size: 16px;
  font-family: $fontName, sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

.fancybox-iframe {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  background: $white;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--video .fancybox-iframe {
  background: transparent;
}

.fancybox-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
  z-index: 99995;
  background: transparent no-repeat;
  cursor: default;
  overflow: visible;
  transform-origin: top left;
  background-size: 100% 100%;
  backface-visibility: hidden;
}

.fancybox-image,
.fancybox-spaceball {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: none;
  max-height: none;
  background: transparent;
  background-size: 100% 100%;
}

.fancybox-controls--canzoomOut .fancybox-placeholder {
  cursor: zoom-out;
}

.fancybox-controls--canzoomIn .fancybox-placeholder {
  cursor: zoom-in;
}

.fancybox-controls--canGrab .fancybox-placeholder {
  cursor: grab;
}

.fancybox-controls--isGrabbing .fancybox-placeholder {
  cursor: grabbing;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-tmp {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
}

.fancybox-error {
  position: absolute;
  margin: 0;
  padding: 40px;
  top: 50%;
  left: 50%;
  width: 380px;
  max-width: 100%;
  transform: translate(-50%, -50%);
  background: #fff;
  cursor: default;
}

.fancybox-error p {
  margin: 0;
  padding: 0;
  color: #444;
  font: 16px/20px $fontName,Helvetica,Arial,sans-serif;
}

.fancybox-close-small {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  outline: none;
  background: transparent;
  z-index: 10;
  cursor: pointer;

  &:after {
    content: '×';
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    font: 20px/30px Arial, "Helvetica Neue", Helvetica, sans-serif;
    color: #888;
    font-weight: 300;
    text-align: center;
    border-radius: 50%;
    border-width: 0;
    background: #fff;
    transition: background .2s;
    z-index: 2;
  }

  &:focus {
    &:after {
      outline: 1px dotted #888;
    }
  }

  &:hover {
    &:after {
      color: $textColor;
      background: $bgColor;
    }
  }
}



.fancybox-slide--video .fancybox-close-small {
  top: -36px;
  right: -36px;
  background: transparent;
}



/* Caption */
.fancybox-caption-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 1em;
  z-index: 99998;
  backface-visibility: hidden;
  background: rgba(0, 0, 0, 0.15);
  opacity: 0;
  transition: opacity .2s;
  pointer-events: none;
}

.fancybox-show-caption .fancybox-caption-wrap {
  opacity: 1; }

.fancybox-caption {
  padding: 1em 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 1.5em;
  font-family: $fontName, Helvetica, Arial, sans-serif;
  color: $white;
  line-height: 1.333;
  text-align: center;
  -webkit-text-size-adjust: none;

  a,
  button {
    pointer-events: all;
  }

  a {
    color: $white;
    text-decoration: underline;
  }
}



/* Buttons */
.fancybox-button {
  display: inline-block;
  position: relative;
  width: 44px;
  height: 44px;
  line-height: 44px;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  background: transparent;
  color: $white;
  vertical-align: top;
  outline: none;

  &:hover {
    background: transparent;

    &:before {
      color: $linkColor;
      transition: color 0.2s ease;
    }
  }

  &:before {
    content: '';
    pointer-events: none;
    position: absolute;
    display: block;
  }


}

.fancybox-button--disabled {
  cursor: default;
  pointer-events: none;

  &:before,
  &:after {
    color: $textColor;
  }

}


.fancybox-button--left {
  border-bottom-left-radius: 5px;

  &:after {
    left: 20px;
    top: 18px;
    width: 6px;
    height: 6px;
    background: transparent;
    border-top: solid 2px currentColor;
    border-right: solid 2px currentColor;
    transform: rotate(-135deg);
  }

}


.fancybox-button--right {
  border-bottom-right-radius: 5px;

  &:after {
    right: 20px;
    top: 18px;
    width: 6px;
    height: 6px;
    background: transparent;
    border-top: solid 2px currentColor;
    border-right: solid 2px currentColor;
    transform: rotate(45deg);
  }

}


.fancybox-button--close {
  float: right;
}


/* Loading spinner */
.fancybox-loading {
  border: 6px solid rgba(100, 100, 100, 0.4);
  border-top: 6px solid rgba(255, 255, 255, 0.6);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  animation: fancybox-rotate .8s infinite linear;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  z-index: 99999;
}

@-webkit-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-controls {
    text-align: left;
  }

  .fancybox-button--left,
  .fancybox-button--right,
  .fancybox-buttons button:not(.fancybox-button--close) {
    display: none !important;
  }

  .fancybox-caption {
    padding: 20px 0;
    margin: 0;
  }
}

/* Fullscreen  */
.fancybox-button--fullscreen:before {
  width: 18px;
  height: 13px;
  left: 11px;
  top: 13px;
  border: 2px solid $linkColor--hover;
  background: none;
}

.fancybox-button--fullscreen:hover:before {
  color: transparent;
  border-color: $linkColor;
}

/* Slideshow button */
.fancybox-button--play:before {
  background: none;
  content: '\f04b';
  color: #dda209;
  font: normal 20px/1 "FontAwesome";
  position: absolute;
  top: 12px;
  left: 16px;
}

.fancybox-button--pause:before {
  background: none;
  content: '\f04c';
  color: #dda209;
  font: normal 20px/1 "FontAwesome";
  position: absolute;
  top: 12px;
  left: 13px;
}

/* Thumbs */
.fancybox-button--thumbs {
  span {
    font-size: 23px;
  }

  &:before {
    background: none;
    content: '\f00a';
    color: #dda209;
    font: normal 22px/1 "FontAwesome";
    position: absolute;
    top: 11px;
    left: 11px;
  }

}



.fancybox-container--thumbs .fancybox-controls,
.fancybox-container--thumbs .fancybox-slider-wrap,
.fancybox-container--thumbs .fancybox-caption-wrap {
  right: 220px;
}


.fancybox-thumbs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 220px;
  margin: 0;
  padding: 5px 5px 0 0;
  background: $white;
  z-index: 99993;
  word-break: normal;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;

  & > ul {
    list-style: none;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 0;

    & > li {
      float: left;
      overflow: hidden;
      max-width: 50%;
      padding: 0;
      margin: 0;
      width: 105px;
      height: 75px;
      position: relative;
      cursor: pointer;
      outline: none;
      border: 5px solid $white;
      border-top-width: 0;
      border-right-width: 0;
      -webkit-tap-highlight-color: transparent;
      backface-visibility: hidden;

      &.fancybox-thumbs-loading {
        background: rgba(0, 0, 0, 0.1);
      }

      & > img {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        max-width: none;
        max-height: none;
        -webkit-touch-callout: none;
        user-select: none;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 2px;
        border: 4px solid $linkColor;
        z-index: 99991;
        opacity: 0;
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      }

      &.fancybox-thumbs-active {
        &:before {
          opacity: 1;
        }
      }
    }
  }
}


/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-thumbs {
    display: none !important;
  }

  .fancybox-container--thumbs .fancybox-controls,
  .fancybox-container--thumbs .fancybox-slider-wrap,
  .fancybox-container--thumbs .fancybox-caption-wrap {
    right: 0;
  }
}
