/* Список подразделов, плитки по 3 колонки */
.category-list {
  @extend .clearfix;

  .category-list__item {
    cursor: pointer;
    float: left;
    padding: 0 0 4em;
    position: relative;
    margin: 0 2em 2em 0;
    width: calc(33.333% - 1.34em);
    transition: all 0.3s ease;
    -webkit-tap-highlight-color: $webkitHighlightTapColor;

    & > a {
      display: block;
    }

    &:nth-child(3n + 3) {
      margin: 0 0 2em 0;
    }

    &:hover {
      transition: all 0.3s ease;

      .category-list__item-title {
        background: $linkColor;
        transition: background 0.3s ease;
      }

      & > a {
        border-color: $linkColor;
        transition: border-color 0.3s ease;
      }
    }


    @include media(lg) {

      &:nth-child(3n + 3) {
        margin: 0 2em 2em 0;
      }

      width: calc(50% - 1em);

      &:nth-child(2n + 2) {
        margin: 0 0 2em 0;
      }
    }

    @include media(md) {
      margin: 0 1em 1em 0;

      &:nth-child(2n + 2) {
        margin: 0 1em 1em 0;
      }

      width: calc(33.333% - 0.666667em);

      &:nth-child(3n + 3) {
        margin: 0 0 1em 0;
      }
    }


    @include media(xmd) {

      &:nth-child(3n + 3) {
        margin: 0 1em 1em 0;
      }

      width: calc(50% - 0.5em);

      &:nth-child(2n + 2) {
        margin: 0 0 1em 0;
      }
    }

    @include media(xs) {
      margin: 0 0 1em;
      float: none;
      width: 100%;

      &:nth-child(3n + 3) {
        margin: 0 0 1em 0;
      }
    }

  }


  .category-list__item-image {
    @extend .img-responsive;
  }

  .category-list__item-title {
    background: $linkColor + 50%;
    color: $white;
    font-size: 17px;
    padding: 1.5em 1em;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;

    @include media(xlg) {
      padding: 1em;
      font-size: 15px;
    }

    @include media(lg) {
      font-size: 14px;
    }
  }

  &.hidden {
    display: none;
  }
}
