/* Стили карточки в каталоге */
.catalog-item {
  background: #fff;
  border: 1px solid $borderColor;
  padding: 0.25em 1em 5em 1em;
  position: relative;


  .fav-link {
    position: absolute;
    top: 0;
    right: 0;

    &:before {
      color: $grey;
      font-size: 15px;
      margin: -11px 0 0 -7px;
    }
    
    &:hover {
      &:before {
        color: $pink;
      }
    }
  }

  .product_photo {
    border: 1px solid #a5a5a5;

    & > .show_product {
      display: block;

      & > img {
        display: block;
        height: auto;
        max-width: 400px;
        width: 100%;
      }
    }
  }


  .catalog-item__title {
    padding: 0.5em 0;
    text-align: center;

    & > .show_product {
      display: block;
      font: bold 21px/1.333 "Roboto";
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      text-decoration: none;
      white-space: nowrap;
    }
  }

  .short_description {
    text-align: center;

    * {
      color: #8f8f8f;
      font-size: 16px;
      line-height: 1.2;
    }
  }


  .catalog-item__bottom {
    position: absolute;
    left: 1em;
    right: 1em;
    bottom: 1em;
  }


  .catalog-item__price-block {
    float: left;
  }


  .prod_price_old {
    line-height: 1;

    & > span {
      color: #8f8f8f;
      font-size: 17px;
      text-decoration: line-through;
    }
  }

  .prod_big_price {
    line-height: 1.333;

    & > span {
      color: #000;
      font-size: 24px;

      &.currency {
        font-size: 19px;
      }
    }
  }


  .kolvo {
    background: $white;
    float: none;
    margin: 0;
    padding: 5px 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    text-align: center;
    visibility: hidden;
    width: auto;
    z-index: 1;
  }


  .kolvo_buy_button_add {
    float: right;
    position: relative;

    & > .btn_add {
      display: inline-block;
      position: relative;
      z-index: 2;
    }
  }


  @include media(xlg) {



    .catalog-item__title {
      & > .show_product {
        font-size: 18px;
      }
    }

    .short_description {
      * {
        font-size: 14px;
      }
    }
  }

}




.desktop {
  .catalog-item {


    .kolvo {
      transition: all 0.3s ease;
      transform: translate3d(0, 0, 0);
    }

    .kolvo_buy_button_add {

      &.hover {
        &:hover {
          .kolvo {
            transform: translate3d(-100%, 0, 0);
            transition: transform 0.3s ease;
            visibility: visible;
          }
        }
      }
    }
  }
}

.flexbox {

  .catalog-item {
    &:before,
    &:after {
      display: none;
    }
  }

  .catalog-item__bottom {
    display: flex;
    justify-content: space-between;
    
    .kolvo_buy_button_add {
      float: none;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
    
    .catalog-item__price-block {
      float: none;
    }

  }
}


.catalog-description__top {
  margin: 0 0 2em;
}

.catalog-description__bottom {
  margin: 0 0 2em;
}

/* Раскладка карточек в каталоге */
.catalog-list {
  margin: 1em 0 0;

  & > .catalog-item {
    float: left;
    margin: 0 2em 2em 0;
    width: calc(33.333% - 1.34em);

    &:nth-child(3n + 3) {
      margin: 0 0 2em;
    }

    @include media(lg) {
      width: calc(50% - 1em);

      &:nth-child(3n + 3) {
        margin: 0 2em 2em 0;
      }

      &:nth-child(2n + 2) {
        margin: 0 0 2em;
      }
    }


    @include media(sm) {
      margin: 0 0 2em;
      width: 100%;
    }

  }

}


.sort_panel {
  font-style: normal;
  font-size: 16px;

  & > span {
    margin: 0 0.5em 0 0;
    position: relative;

    & > a {
      margin: 0 3px 0 0;
    }

    &.this_sort {
      font-weight: bold;
    }

    &.desc_sort {
      &:after {
        content: "\2193";
      }
    }

    &.asc_sort {

      &:after {
        content: "\2191";
      }
    }

    &:first-child {
      margin: 0 0.5em;
    }
  }

  @include media(md) {
    font-size: 14px;
  }

  @include media(sm) {
    font-size: 12px;
  }

}


