/* Базовые: Ширина контента */
$containerWidth: 1200px;
/* Базовые: Высота компьютерной фиксированной шапки */
$desktopFixedHeaderHeight: 38px;
/* Базовые: Семейство шрифта */
$fontName: "Roboto";
/* Базовые: Размер шрифта */
$fontSizeBase: 14px;
/* Базовые: Белый цвет */
$white: #fff;
/* Базовые: Черный цвет */
$black: #222;
/* Базовые: Цвет ссылок */
$linkColor: #00487e;
/* Базовые: Цвет ссылок при наведении */
$linkColor--hover: #edac0a;
/* Базовые: Какой-то цвет */
$webkitHighlightTapColor: rgba(0,0,0, 0.15);
/* Базовые: Цвет текста */
$textColor: #494949;
/* Базовые: Черный цвет */
$grey: #666;
/* Базовые: Розовый цвет */
$pink: #d9017f;
/* Базовые: Какой-то цвет иконок */
$iconColor: $linkColor--hover;
/* Базовые: Цвет фона */
$bgColor: #ffffff;
/* Базовые: Цвет фона верхней шапки */
$headerTopBgColor: #00487e;
/* Базовые: Цвет фона нижней шапки */
$headerBottomBgColor: #fff;
/* Базовые: Цвета ошибок */
$colorError: rgba(253, 30, 15, 0.7);
/* Базовые: Цвета ОК */
$colorOk: rgba(0,248,6,0.7);
/* Базовые: Цвет фона верхней шапки на мобильных */
$headerTopBgColorMobile: $linkColor;
/* Базовые: Высота мобильной фиксированной шапки */
$mobileFixedHeaderHeight: 44px;
/* Футер: Верхний цвет фона */
$footerTopBgColor: #9ac3e2;
/* Футер: Нижний цвет фона */
$footerBottomBgColor: $headerTopBgColor;
/* Футер: Нижний цвет фона */
$footerLinkColor: $linkColor;
/* Рамки: Цвет */
$borderColor: #aaa;
/* Рамки: Скругление */
$borderRadius: 0px;
/* Рамки: Стиль */
$border: 1px solid $borderColor;
/* Кнопки: Градиент да/нет? Если нет, используется цвет 1 */
$isButtonGradient: true;
/* Кнопки: Тень, да/нет */
$isButtonShadow: false;
/* Кнопки: Рамка, да/нет */
$isButtonBorder: false;
/* Кнопки: Цвет фона 1 (нижний) */
$buttonBgColor1: #00487e;
/* Кнопки: Цвет фона 2 (верхний) */
$buttonBgColor2: #00487e;
/* Кнопки: Тень */
$buttonShadow: 1px 2px 3px 0 #a8a8a8;
/* Кнопки: Цвет текста */
$buttonColor: #fff;
/* Кнопки: Скругления */
$buttonBorderRadius: $borderRadius;
/* Кнопки: Цвет фона 1 (нижний) при наведении */
$buttonBgColor1--hover: #215e03;
/* Кнопки: Цвет фона 2 (верхний) при наведении */
$buttonBgColor2--hover: #215e03;
/* Кнопки: Цвет текста при наведении */
$buttonColor--hover: #fff;
/* Кнопки: Рамка */
$buttonBorder: 1px solid #0c3658;
/* Кнопки: Цвет рамки при наведении */
$buttonBorderColor--hover: #215e03;
/* Кнопки: Тень наведении */
$buttonShadow--hover: none;
/* Кнопки: Цвет фона 1 (нижний) активный */
$buttonBgColor1--active: #34800f;
/* Кнопки: Цвет фона 2 (верхний) активный */
$buttonBgColor2--active: #34800f;
/* Кнопки: Цвет текста активный */
$buttonColor--active: #fff;
/* Кнопки: Цвет рамки активный */
$buttonBorderColor--active: #34800f;
/* Кнопки: Тень в активном состоянии */
$buttonShadow--active: none;
/* Инпуты: Есть ли рамка */
$isInputBorder: true;
/* Инпуты: Есть ли тень */
$isInputShadow: true;
/* Инпуты: Цвет текста */
$inputColor: $textColor;
/* Инпуты: Цвет фона */
$inputBg: #e2e2e2;
/* Инпуты: Цвет рамки */
$inputBorderColor: #050f29;
/* Инпуты: Тень */
$inputShadow: inset 0 2px 7px 0 #a6a6a7;
/* Модули: Цвет фона */
$moduleBg: #fff;
/* Модули: Цвет фона заголовков */
$moduleHeaderBg: $linkColor;
/* Модули: Цвет заголовков */
$moduleHeaderColor: #fff;
/* Модули: Рамка */
$moduleBorder: 0;
/* Модули: Тень сверху */
$module_top__box-shadow: 0px 2px 3px #808080;
/* Модули: Цвет фона заголовков в мобильной версиии */
$moduleMobileHeaderBg: #c1c1c1;
/* Модули: Цвет заголовков в мобильной версиии */
$moduleMobileHeaderColor: #2c2c2c;
/* Горизонтальное меню: Градиент да/нет Если нет, используется цвет 1 */
$isTopMenuGradient: true;
/* Горизонтальное меню: Тень да/нет */
$isTopMenuShadow: false;
/* Горизонтальное меню: Рамка да/нет */
$isTopMenuBorder: false;
/* Горизонтальное меню: Закругления да/нет */
$isTopMenuBorderRounded: false;
/* Горизонтальное меню: Цвет фона 1 (нижний) */
$topMenuBgColor1: #215e03;
/* Горизонтальное меню: Цвет фона 2 (верхний) */
$topMenuBgColor2: #215e03;
/* Горизонтальное меню: Рамка */
$topMenuBorder: 1px solid #aaa;
/* Горизонтальное меню: Тень кнопок */
$topMenuShadow: 1px 2px 3px 0 #a8a8a8;
/* Горизонтальное меню: Цвет текста */
$topMenuColor: #00ff48;
/* Горизонтальное меню: Скругления */
$topMenuBorderRadius: 0px;
/* Горизонтальное меню: Цвет текста при наведении */
$topMenuColor--hover: #edac0a;
/* Горизонтальное меню: Цвет фона 1 (нижний) */
$topMenuBgColor1--selected: #00487e;
/* Горизонтальное меню: Цвет фона 2 (верхний) выбранный раздел */
$topMenuBgColor2--selected: #00487e;
/* Товар: Цвет заголовка */
$prodTitleColor: $linkColor;
/* Товар: Цвет текста */
$prodTextColor: $textColor;
/* Товар: Цвет текста следующий */
$prodSecondColor: #8f8f8f;
/* Товар: Цвет текущей цены */
$prodCurrentPriceColor: $black;
/* Товар: Цвет старой цены */
$prodOldPriceColor: #a4a4a4;