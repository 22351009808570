.mobile,
.tablet {
   .call-order.button {
	border: 1px solid;
   }

  nav {
    background: $linkColor;
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 260px;
    z-index: 1;
    text-align: left;
    transition: transform 0s ease-out, visibility 0s;
    transform: translate3d(0, 0, 0);
    -webkit-overflow-scrolling: touch;

    &.show-menu {
      display: block !important;
      height: calc(100vh - 3em);
      transform: translate3d(0, 0, 0) !important;
      transition: transform 0.4s ease-out;
      visibility: visible !important;
    }

    .header-contacts {
      .phones {

      }
    }

    .arrow {
      height: 38px;
      outline: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 38px;
      transition: transform 0.3s ease;
      transform: translate3d(0,0,0);
      -webkit-tap-highlight-color: $webkitHighlightTapColor;
      will-change: transform;
      z-index: 10;

      &:before {
        @include icon(12, 1) {
          position: absolute;
          margin-left: -7px;
          margin-top: -4px;
          height: 8px;
          width: 14px;
          left: 50%;
          top: 50%;
          transition: transform 0.3s ease;
          will-change: transform;
        }
      }
    }


    .menu_level_1 {
      display: block;
      margin-bottom: 3em;

      & > li {
        display: block;
        width: auto;

        &.selected {

          & > .mob-icon {
            color: #222 !important;
          }
        }

        &.subcat {
          & > a {
            width: calc(100% - 38px);
          }
        }

        &.open {
          .menu_level_2 {

          }

          .arrow {
            transform: translate3d(0,0,0);
            transition: transform 0.3s ease;



            &.rotate {

              &:before {
                transform: rotate(180deg);
                transition: transform 0.3s ease;
              }

            }
          }
        }

        & > a {
          padding: 1em 1em 1em 3.5em;
          font-size: 11px;
          text-align: left;
        }

      }

      .mob-icon {
        top: 0.8em;
        left: 1em;
      }
    }

    .menu_level_2 {
      background: rgba(55,55,55,0.7);
      display: none;
      position: static;
      overflow: hidden;
      opacity: 1;
      transition: all 0s;
      visibility: visible;
      will-change: transform;
      z-index: 5;

      & > li {
        display: block;
        padding: 0;

        & > a {
          padding: 0.5em 1em 0.6em 4em;
          color: $bgColor;
          font-size: 13px;
        }
      }

      .mob-icon {
        display: none;
      }
    }

    .menu_level_3 {
      background: none;
      display: none;
    }
  }
}



.mobile,
.tablet {

  .nav-left {
    padding: 0;
  }


  nav {
    .header-contacts {
      padding: 1em;


      .phones-list {
        display: inline-block;
      }

      .phones-list__item {
        display: block;
        list-style: none;

        & > a {
          color: $white;
          text-decoration: none;
        }
      }

      .call-order-wrapper {
        text-align: center;
      }
    }


    .call-order-wrapper {
      text-align: center;
    }
  }
}


/* Левое меню */


.mobile,
.tablet {
  .nav-left {

    .menu_level_1 {
      display: block;

      & > li {
        position: relative;

        .mob-icon {
          margin-top: -7px;
          top: 50%;
          left: 0.5em;
        }

        & > a {
          padding: 10px 1em 8px 2em;
        }
      }
    }
  }
}

