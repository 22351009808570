@import "../system/icon";


.header-bottom {
	.logo {
		float: left;
		padding: 0 1em;
		width: 17%;
	}

	.header-contacts {
		margin-top: 23px;
	}
	.header-contacts.count_col_4 {
		float: right;
		padding: 1em;
		width: 75%;
	}

	.header-contacts.count_col_3 {
		float: right;
		padding: 1em;
		width: 80%;
	}

	.header-contacts {
		.phones, .mail, .times-block {
			/*padding: 0 2em;*/
		}
	}
	.phones {
		/*width: 25%;*/
	}

	.mail {
		/*width: 25%;*/

		.mail-wrapper {
			padding: 0 0 0 2em;
		}
	}

	.times-block {
		/*width: 25%;*/
	}

	.header-cart {
		/*width: 25%;*/
	}

	.header-contacts.count_col_3 {
		.phones, .mail, .times-block, .header-cart {
			/*width: 33%;*/
			display: inline-block;
			vertical-align: top;
		}
	}

	.header-contacts.count_col_4 {
		.phones, .mail, .times-block, .header-cart {
			width: 25%;
		}
	}

	@include media(lg) {

		.logo {
			text-align: center;
			/*width: 100%;*/
		}

		.phones {
			width: 50%;

			.phones-list {
				&:before {
					left: 0;
				}
			}
		}

		.times-block {
			width: 50%;
		}

		.header-cart {
			float: right;
			padding: 0 1em 0 0;
			text-align: left;
			width: 50%;
		}

		.header-contacts {
			padding: 0 1em;
			width: 100%;


			.mail {
				float: left;
				padding: 0 1em 0 0;
				width: 50%;
			}
		}
	}

	@include media(xmd) {

		.logo {
			float: none;
			text-align: center;
			width: 100%;

			& > a {
				display: block;
			}

			img.logo-img {
				display: inline-block;
				width: 70%;
			}
		}

		.header-contacts {
			float: none;
			padding: 0 1em 1em;
			width: 100%;
		}
	}

}
/* конец раскладки */



.header-bottom {
	border-top: 10px solid $linkColor;

	& > .container {
		position: relative;
	}

	.logo {
		margin: 0.333em 0;

		.logo-img {
			display: inline-block;
			height: auto;
			max-width: 268px;
			width: 100%;
		}
	}

	.phones {
		position: relative;
		margin: 0;

		.phones-list {
			@include fontawesome('\f095') {
				color: $iconColor;
				left: 0;
				top: initial;
				line-height:3;
			}

			&:before {
				@include media(xs) {
					line-height:2;
				}
			}
		}
	}

	.phones-list {
		display: inline-block;
		list-style: none;
		margin: 0;
		position: relative;
		vertical-align: middle;
		padding-left:2em;
	}

	.phones-list__item {

		& > a {
			display: block;
			color: $linkColor;
			font-size: 20px;
			font-weight: bold;
			text-decoration: none;
			white-space: nowrap;
			padding-bottom: 10px;
		}

		.pref {
			font-weight: normal;
		}
	}

	.mail {
		margin: 0;
		padding: 0px 13em;

		.mail-wrapper {
			position: relative;
			margin: 0 0 1em;

			.icon {

				@include fontawesome('\f0e0') {
					color: $iconColor;
					left: 0;
					top: initial;
				}
			}

			@include media(lg) {
				margin: 0 0 0.5em;
			}
		}

	}

	.mail-link {
		font-size: 15px;
		line-height: 1;
		vertical-align: middle;
		white-space: nowrap;
	}


	.call-order {
		white-space: nowrap;
	}


	.times-block {
		margin: 0;

		.title {
			color: $linkColor--hover;
			font-size: 15px;
			/*line-height: 1;*/
			margin: 0px;
			text-transform: uppercase;
		}
		& > * {
			padding-bottom: 0px;
		}
	}


	.times {
		color: $textColor;
		font-size: 15px;
		padding: 0 0 0 1.5em;
		position: relative;
		white-space: nowrap;

		& > span {
			color: $linkColor + 70%;
		}

		@include media(xlg) {
			font-size: 14px;
		}

		@include media(lg) {
			font-size: 13px;
			padding-right: 1em;
		}

		@include fontawesome('\f017') {
			color: $linkColor;
			left: 0;
			top: initial;
			margin-left: 0;
		}

	}

	@include media(lg) {

		.logo {
			margin: 0.5em 0;
		}

		.mail,
		.phones {
			margin: 0 0 1em;
		}

		.phones-list__item {

			& > a {
				font-size: 15px;
			}
		}

	}

}


.header-cart {
	cursor: default;
	padding: 0 0 5px;
	text-align: right;
	user-select: none;

	&.open {

		.cart {
			&.full {
				&:before {
					color: $iconColor;
				}
			}
		}
	}


	.cart {
		display: inline-block;
		position: relative;
		text-align: left;
		max-width: 150px;
		white-space: nowrap;
		width: 100%;

		@include fontawesome('\f07a') {
			color: $iconColor;
			font-size: 34px;
			margin: -19px 0 0;
			left: 0;
		}



		& > .header-cart__title-mob {
			display: none;
		}

		& > .header-cart__title {
			color: $linkColor--hover;
			font-size: 15px;
			line-height: 1;
			text-transform: uppercase;
		}

		.summ-block {
			padding: 0 0 0 3em;
		}

		& > .count {
			padding: 0 0 0 3em;

			& > .count-text {
				display: inline-block;
			}

			& > .count-quantity {
				display: inline-block;
			}
		}
	}
}


.desktop {

	.header-cart {


		.cart {

			&.full {
				cursor: pointer;
			}

			&:not(.empty) {
				&:hover {
					&:before {
						color: $linkColor;
					}
				}
			}
		}
	}


	@include media(lg) {
		.header-bottom {
			padding: 0 0 1em;
		}
	}
}
