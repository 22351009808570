.news-detail {
  margin: 1em 0 0;

  .news-detail__header {

  }

  .news-detail__title {
    font-size: 18px;
  }

  .news-detail__text {
    & > a {
      float: left;
      outline: none;
    }
  }


  .news-detail__image {
    display: block;
    margin: 0 1em 0 0;
  }



  @include media(sm) {

  }
}

