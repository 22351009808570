.header-cart {
	&.open {

		.ajaxflowcart-block {
			opacity: 1;
			transform: translate3d(0, 0, 0);
			transition: transform 0.3s ease, opacity 0.15s ease 0.15s;
			visibility: visible;
		}
	}
}


.cart {
	display: inline-block;
	text-align: left;
	position: relative;
	-webkit-tap-highlight-color: rgba(0,0,0,0.15);


	&.empty {
		cursor: default;
	}

	.header_cart-goods-quantity {
		color: $linkColor;
	}


	&.full {
		cursor: pointer;
	}
}



.desktop {
	.cart {
		&.full {
			&:hover {

				.cart-title {
					color: $linkColor;
					transition: color 0.2s ease;
				}

				&:before {
					color: $linkColor;
					transition: color 0.2s ease;
				}
			}
		}
	}
}



// Корзина /ishop/cart


.cart-table {
	margin: 0 0 1em;
	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;
	text-align: center;
	vertical-align: middle;

	.cart-table__delete-btn {
		cursor: pointer;
		display: inline-block;
		font-style: normal;
		width: 32px;
		height: 32px;
		border-radius: $borderRadius;
		text-align: center;
		line-height: 28px;
		padding: 0;
		position: relative;
		-webkit-tap-highlight-color: $webkitHighlightTapColor;

		&:hover {
			&:before {
				color: $linkColor--hover;
				transition: color 0.2s ease;
			}
		}

		@include fontawesome('\f00d') {
			color: $linkColor;
			font-size: 21px;
			margin: -14px 0 0 -8px;
		}

	}


	.cart_product_photo img {
		height: auto;
		max-width: 100px;
		width: 100%;
	}

	.input-count {
		padding: 5px 9px 5px 1em;
		max-width: 76px;
		width: 100%;
	}

	& > thead {
		& > tr {
			border-bottom: 1px solid $borderColor;

			& > th {
				padding: 6px 0;

				&:nth-child(1) {
					width: 15%;
				}
				&:nth-child(2) {
					width: 40%;
				}
				&:nth-child(3) {
					width: 14%;
				}
				&:nth-child(4) {
					width: 10%;
				}
				&:nth-child(5) {
					width: 16%;
				}
				&:nth-child(6) {
					width: 5%;
				}

			}
		}
	}

	& > tbody {
		& > tr {
			border-bottom: 1px solid $borderColor;

			& > td {
				padding: 6px 0;
			}
		}
	}
}

.cart-table__skidki {
	width: 100%;
}

.cart-table__deliver {
	margin: 0 0 1em;
	width: 100%;

	.dostavka_radio {

		& > label {
			padding: 0;
			height: 24px;
			width: 24px;
			display: block;
		}
	}


	.radio {
		&:not(checked) {
			& + label {
				&:before {
					background: $white;
					left: 3px;
				}

				&:after {
					left: 6px;
				}
			}
		}
	}

	.dostavka_header {
		vertical-align: top;
	}

	tr {
		border-bottom: 1px solid $borderColor;
	}

	td {
		padding: 3px 0;
	}

	tfoot {
		& > tr {
			border-bottom: none;
		}
	}
}


.order_ok_table {
	table-layout: fixed;
	text-align: left;
	width: 100%;

	th,
	td {
		padding: 6px 0;
	}

	tr {
		border-bottom: 1px solid $borderColor;

		&:last-child {
			border-bottom: none;
		}
	}

}


