.compareCategories {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 0 0 2em;


  .trashAll {
    margin: 0 5px;
    padding: 4px 10px;
    cursor: pointer;
    background: #fff;
    outline: 1px solid #aaa;
  }

}


.comparison,
.comparisonz {
/* display: flex;*/

}

.comparisonz {
  padding: 0 0 5px;
}

.comparison .owl-stage-outer .owl-stage .owl-item {
  display: inline-block;
  vertical-align: top;
}

.comparison.owl-carousel .owl-nav .owl-prev,
.comparison.owl-carousel .owl-nav .owl-next {
  background: #e2e2e2;
  border-radius: 50%;
  color: #fff;
  line-height: 0.96em;
  opacity: 1;
  height: 1em;
  width: 1em;
  text-align: center;
}



.comparison.owl-carousel .owl-nav .owl-prev:hover,
.comparison.owl-carousel .owl-nav .owl-next:hover {
  color: #aaa;
}

.comparison.owl-carousel .owl-nav .owl-next {
  left: auto;
  right: -0.5em;
}

.comparison.owl-carousel .owl-nav .owl-prev {
  left: -0.5em;
  right: auto;
}

.comparison.owl-carousel .owl-nav {
  top: 50%;
}



.comparison {

  .container {
    background: $white;
    border: 1px solid $borderColor;
    text-align: center;
  }

  .comparisonFullItem {

    .image-link {
      & > img {
        width: 100%;
      }
    }


    &:hover {
      .removeItem {
        opacity: 1;
        transition: opacity 0.2s ease;
      }
    }
  }


  .btn_add {
    position: absolute;
    bottom: 1em;
    left: 0;
    right: 0;
  }

  .general {
    padding: 10px 0 5em;
    margin: 0 0 2px;
    position: relative;

    & > img {
      width: 100%;
    }

    .numbered {
      width: 30px;
      position: absolute;
      background: $linkColor;
      color: $white;
      border-radius: 0 0 26px;
      top: 0;
      left: 0;
      padding: 0 5px 5px 1px;
    }


    .image-link {
      display: block;
    }

    .removeItem {
      padding: 10px 0;
      cursor: pointer;
      position: absolute;
      top: 0;
      opacity: 0;
      right: 0;
      height: 28px;
      width: 28px;
      text-indent: -9999px;
      -webkit-tap-highlight-color: $webkitHighlightTapColor;

      @include fontawesome('\f00d') {
        color: $linkColor;
        top: 50%;
        margin: -14px 0 0 -6px;
        text-indent: 0;
      }

      &:hover {
        &:before {
          color: $linkColor--hover;
          transition: color 0.2s ease;
        }
      }
    }
  }
}





.comparison .container > div .linkTitle {
  text-transform: uppercase;
  padding: 0 0 5px;
}

.comparison .container > div .head {
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 0 0 4px -1px #464646;
  background: #f7f7f7;
  font-size: 12px;
  cursor: pointer;
}

.comparison .container > div .priceOld {
  text-decoration: line-through;
  color: #aaa;
}

.comparison .container > div .generalParam:nth-child(odd) {
  background: #ccc;
}

.comparison .container > div .generalParam .inners{
  padding: 5px 0;
}

.comparison .container > div .generalParam .inners b {
  font-size: 20px;
  line-height: 14px;
}

.comparison .container > div .generalParam .param_tsvet {
  display: flex;
  flex-flow: row wrap;
  justify-content: center
}

.comparison .container > div .generalParam .param_tsvet .colorPunkt {
  width: 30px;
  height: 30px;
  margin: 0 4px;
  border-radius: 15px;
}

.compare_box {
  display: inline-block;
  padding: 0 5px;
}

.compare_box input[type=checkbox] {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

.compare_text {
  cursor: pointer;
  display: inline-block;
  font-size: 12px;

  &.in_compare {
    color: $linkColor;
    text-decoration: underline;

    &:hover {
      color: $linkColor--hover;
      transition: color 0.2s ease;
      text-decoration: underline;
    }
  }
}


.owl-placeholder {
	height: 100px;
	float: left;
}
