.news-block__title {
	padding: 3px 0;

	& > a {
		font-size: 16px;
		text-transform: uppercase;
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}
}


.news-block__text {
	font-size: 14px;
	margin: 0.5em 0;
	line-height: 1.333;
	text-align: justify;
}


.news-block__date, .news-detail__date {
	text-align: center;
	vertical-align: middle;
	width: 85px;

	& > span {
		background: $linkColor;
		color: #fff;
		font-size: 13px;
		padding: 0 8px 1px;
		white-space: nowrap;

		&.news-publish, &.pbl {
			background: none;
			display: block;
			color: $textColor;
			font-size: 10px;
			padding: 0;
		}
	}
}




/* Новости на главной     */
/* ...в левой колонке     */
.news-block {

	.news-block__item {
		margin: 0 0 1em;
	}


	.news-block__header {
		display: table;
		width: 100%;
	}


	.news-block__date,
	.news-block__title {
		display: table-cell;
	}


	.news-block__date {
		width: 68px;
	}


	.news-block__image {
		@extend .img-responsive;
	}


	.readmore-link {
		display: block;
	}

	.news-all {
		margin: 7px 0 0;
		text-align: center;

		& > a {
			font-size: 19px;
			text-transform: uppercase;
		}
	}

	@include media(xmd) {
		.news {
			width: calc(50% - 1em);

			&:last-child {
				width: 100%;
			}
		}
	}

	@include media(sm) {
		.news {
			width: 100%;
		}
	}
}



/* Новости на главной     */
/* ... в контент области  */
.module-inner {
	.news-block {
		.news-block__item {
			float: left;
			margin: 0 2em 1em 0;
			width: calc(33.333% - 1.5em);

			&:nth-child(3) {
				margin: 0 0 1em;
			}
		}
	}
}


/* Список новостей - news/page/1 */
.news-list, .news-detail {

	.readmore-link {
		display: block;
		margin: 1em 0 0;
	}

	.news-block__image {
		float: left;
		margin: 0 1em 0.5em 0;
		max-width: 200px;
	}

	.news-block__item {
		@extend .clearfix;

		/*border-bottom: 1px solid $borderColor;*/
		margin: 0 0 2em;
		padding: 0 0 1em;

		&:last-child {
			/*border-bottom: none;*/
		}
	}

	.news-block__header, .news-detail__header {
		display: table;
		width: 100%;

		.news-block__date,
		.news-block__title,
		.news-detail__date,
		.news-detail__title {
			display: table-cell;
		}
	}
}




