input::-ms-clear {
  display: none;
}


html {

}


body {
  background: $bgColor;
  font: normal #{$fontSizeBase}/1.5 $fontName, "Helvetica", sans-serif;
  color: $textColor;
  opacity: 0;
  overflow: hidden;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
}


.loaded {
  body {
    overflow-x: hidden;
    overflow-y: scroll;
    height: auto;
    opacity: 1;
  }

}


table {
  border-collapse: collapse;
}


ul {
  margin-top: 0;
  margin-bottom: 0;
}

.content-inner ul {
  padding-left: 18px;
}

h1 {
  color: $linkColor;
  font-size: 28px;
}

h2 {
  color: $linkColor;
  font-size: 24px;
}

h3 {
  color: $linkColor;
  font-size: 18px;
}


h1 , h2, h3, h4, h5, h6, p {
  padding: 0 0 1em;
}


a {
  color: $linkColor;
  transition: color 0.3s ease;
  text-decoration: underline;
  -webkit-tap-highlight-color: $webkitHighlightTapColor;
}


a:hover {
  color: $linkColor--hover;
  text-decoration: underline;
  transition: color 0.3s ease;
}


.alert {
  color: red;
}



.noscript_alert {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid red;
  text-align: center;
}

.noscript_alert > span {
  font-weight: bold;
  color: red;
}


.content-inner {
  margin: 1em 0 3em;


  .title {
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: bold;
  }

  @include media(md) {
    padding: 0 1em;
  }

  .restore_pass-section {
    display: block;
    margin: 1em 0 0;
    max-width: 320px;
    padding: 1em;

    & > .popup-block__cross {
      display: none;
    }

    & > .popup-block__title {
      display: none;
    }

    & > .restore_pass-form {
      max-width: 292px;


      .form_block {
        margin: 0;
        
        .form_title {
          position: static;
        }

        &.key-title {
          margin: 0 0 0.5em;
        }
      }

      .input-text {
        width: 100%;
      }
    }
  }

}



.col-3 {
  float: left;
  padding: 0 1em;
  width: 25%;
}

.col-4 {
  float: left;
  padding: 0 1em;
  width: 33.333%;
}

.col-6 {
  float: left;
  padding: 0 1em;
  width: 50%;
}


.col-3-r {
  float: right;
  padding: 0 1em;
  width: 25%;
}

.col-4-r {
  float: right;
  padding: 0 1em;
  width: 33.333%;
}

.col-6-r {
  float: right;
  padding: 0 1em;
  width: 50%;
}


@include media(md) {

  .col-3 {
    width: 50%;
  }

  .col-4 {
    width: 50%;
  }

  .col-6 {
    width: 100%;
  }

  .col-3-r {
    width: 50%;
  }

  .col-4-r {
    width: 50%;
  }

  .col-6-r {
    width: 100%;
  }
}



@include media(sm) {
  .col-3,
  .col-4,
  .col-6,
  .col-3-r,
  .col-4-r,
  .col-6-r {
    width: 100%;
  }


}


.path {
  color: $linkColor;
  display: block;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  line-height: 1em;
  padding: 2em 0 1.5em;
  position: relative;

  &:before {
    background: linear-gradient(to right, $borderColor 0%, $borderColor 15%, rgba(255,255,255,0) 70%);
    content: '';
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  & > a {
    font-weight: normal;
  }
}





/* Вид хлебных крошек на мобильных */
.mobile,
.tablet {

  @include media(md) {
    .path {
      background: $headerTopBgColorMobile;
      color: $white;
      font-size: 12px;
      padding: 12px 15px 14px;
      
      & > a {
        color: $white;
      }

      &:before {
        display: none;
      }
    }
  }
}



.content {
  position: relative;

  img {
    height: auto;
    max-width: 800px;

    @include media(lg) {
      max-width: 600px;
    }

    @include media(md) {
      max-width: 100%;
    }
  }
}

button,
.button {
  @include button(); /* Основные стили настроек кнопки см. /src/scss/vars */

    font-size: 14px;
    outline: none;
    text-transform: uppercase;
    transition: all 0.3s ease;
}



.yashare_text {
  text-align: right;
}

html.ie9 * {
  border-radius: 0 !important;
}


.wrapper {
  height: 100vh;
  overflow-x: hidden;
  pointer-events: none;
  position: relative;
  will-change: transform;
  z-index: 1;
}

.loaded {
  .wrapper {
    height: auto;
    overflow: hidden;
    pointer-events: all;
    visibility: visible;
  }
}


.mobile,
.tablet {
  
  &.menu-visible {
    .overlay {
      visibility: hidden;
    }
  }

  .wrapper {
    background: $bgColor;
    box-shadow: -9px 0 37px rgba(0,0,0,0.3);
    padding: $mobileFixedHeaderHeight 0 0;
    position: relative;
    will-change: transform;
    z-index: 10;
  }
}


.locked {
  .overlay {
    opacity: 1;
    transition: opacity 0.2s cubic-bezier(0, 0, 0, 1);
    visibility: visible;
  }
}


.icon {
  display: block;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 50%;
  left: 0;
  margin: -12px 0 0;
}


.overlay {
  position: fixed;
  background: rgba(0,0,0,0.65);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
}


.mob-icon {
  font: normal 1.5em/1.2 "FontAwesome";
  position: absolute;
  pointer-events: none;
}


.desktop {
  .mob-icon {
    visibility: hidden;
  }
}


.citronLoader {
  position:absolute;
  top: 0;
  left: 0;
  background: white;
  background: rgba(255, 255, 255, .8);
  z-index: 2;
}

.citronLoader span {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
  width:32px;
  height:32px;
  background:url(/assets/images/ajaxloader.gif) 0 0 no-repeat;
}

.citronLoaderBig {
  position:absolute;
  top: 0;
  left: 0;
  background:white;
  background:rgba(255, 255, 255, .8);
  z-index:2;
}

.citronLoaderBig span {
  position:absolute;
  top:100px;
  left:48%;
  width:66px;
  height:66px;
  background:url(/assets/images/citronloader.gif) 0 0 no-repeat;
}

#ajaxedsearch {
  position:absolute;
  z-index:-1;
  width:1px;
  height:1px;
  overflow:hidden;
}


.content-block.filtrovannoe {
  padding: 10px;
}


.prd_pages_bottom,
.prd_pages_top {
  color: $textColor;
  font-size: 13px;
  text-align: right;

  & > a {
    display: inline-block;
    padding: 0 4px;

    &.sel_page {
      color: $textColor;
      text-decoration: none;
    }
  }
}


.example {
  background: $linkColor;
  color: #fff;
  font-weight: 700;
  display: inline-block;
}


.video-responsive {
  position: relative;
  padding-bottom: 42%;
  height: 0;
  overflow: hidden;
  margin: 0 auto;
  max-width: 73%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.redz {
  color: #df141e;
}


.radio {
  vertical-align: top;
  width: 17px;
  height: 17px;
  margin: 0 6px 0 0;

  & + label {
    cursor: pointer;
  }

  &:not(checked) {
    position: absolute;
    visibility: hidden;

    &  + label {
      position: relative;
      padding: 0 0 0 35px;

      &:before {
        border-radius: 50%;
        background: #c1c1c1;
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -8px;
        left: 0;
        width: 16px;
        height: 16px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 3px;
        width: 10px;
        height: 10px;
        margin-top: -5px;
        border-radius: 50%;
        background: $linkColor;
        opacity: 0;
        transition: background .2s ease;
      }
    }
  }

  &:checked {
    & + label {
      &:after {
        opacity: 1;
      }
    }
  }
}


.checkbox {
  vertical-align: top;
  margin: 0 3px 0 0;
  width: 17px;
  height: 17px;

  & + label {
    cursor: pointer;
    user-select: none;
  }

  &:not(checked) {
    position: absolute;
    visibility: hidden;

    & + label {
      position: relative;
      padding: 0 0 0 24px;

      &:before {
        border: 1px solid $borderColor;
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -6px;
        width: 12px;
        height: 12px;
        border-radius: 0;
        background: $bgColor;
      }


      &:after {
        content: '\f00c';
        color: $linkColor;
        font: normal 16px/1 "FontAwesome";
        position: absolute;
        margin-top: -8px;
        top: 50%;
        left: 1px;
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &:checked {
    & + label:before {

    }

    & + label:after {
      opacity: 1;
      transition: opacity 0.2s ease;
      visibility: visible;
    }
  }


}



